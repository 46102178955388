export const environment = {
    production: true,
    "env": "rc",
    "baseUrl": "https://smrc.instecservices.com/",
    "applicationEndpoint": "https://smrc-api.instecservices.com",
    "syncHost": "https://smrc-sync.instecservices.com",
    "documentationUrl": "https://smrc-api-portal.instecservices.com",
    "access_token":"access_token_rc",
    "applicationInsights": "InstrumentationKey=c762759a-b70f-4a8b-8e7b-09808de6f3b3;IngestionEndpoint=https://eastus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/",
    "auth": {
        "domain": "instecdev.auth0.com",
        "clientId": "ZwMVAQCO04bpDntUs06rN7QYFjkWrmZi",
    },
    "qlik": {
        "authUrl": "https://uat1.insuritybi.insurity.com/sure-uw/hub/",
        "reportsUrl": "https://uat1.insuritybi.insurity.com/sure-uw/sense/app/6613a338-8154-422c-be9c-24c3e633b667/sheet/681cc4bf-2fea-402e-af05-3e49d7b19ca6"
    },
    "ikcDocumentationUrl": "https://docs.insurity.com/category/sureunderwriting",
    "ikcIntegrationDocumentationUrl": "https://docs.insurity.com/bundle/SureUnderwritingUserHelp/page/webhooks.html",
    "requireAPIM": true
};